<template>
  <div class="check_container">
    <div class="content">
      <div class="top flex_sp_c">
        <div>
          <span class="c3">核对订单信息</span>
          <span class="orange">【为了保证货品数量，请在<span v-if="endtime.h>0">{{endtime.h}}小时</span><span v-if="endtime.i>0">{{endtime.i}}分钟</span><span v-if="endtime.s>0">{{endtime.s}}秒</span>内进行订单支付】</span>
        </div>
        <div class="flex_row c9 step">
          <div class="cart_item">
            <span class="color_theme">我的购物车</span>
            <div class="green">
              <span>1</span>
              <div class="line active_bg"></div>
            </div>
          </div>
          <div class="cart_item">
            <span class="color_theme">核对订单信息</span>
            <div class="green">
              <div class="line1 active_bg"></div>
              <span>2</span>
              <div class="line active_bg"></div>
            </div>
          </div>
          <div class="cart_item">
            <span>成功提交订单</span>
            <div class="grey">
              <div class="line1"></div>
              <span>3</span>
            </div>
          </div>
        </div>
      </div>

      <div class="address">
        <div class="nav flex_sp_c">
          <p>确认收货地址</p>
          <div class="c_p" @click="$router.push('/index/cart')">
            返回购物车继续添加
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="main">
          <div class="tip">
            <i class="el-icon-warning-outline"></i>
            <span> 收货信息与配送方式填写后只能让客服修改，所有货物请在送货员在场时开箱验货再签收，如有破损请及时联系客服人员，如未当面开箱验货，破损不予赔付，自行承担。 </span>
          </div>
          <div class="info">
            <el-form :model="form" :rules="rules" label-width="100px">
              <el-form-item label="选择配送：" prop="deliverymethod_id">
                <el-select v-model="form.deliverymethod_id" placeholder="请选择" @change="getFreight">
                  <el-option v-for="item in deliverymethod" :key="item.id" :label="item.title" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="配送地址：" v-if="show">
                <div>
                  <div>{{ addressList.address_str }}</div>
                  <div>
                    <span>收货人：{{ addressList.name }}</span>
                    <span class="ml_20"> 电话：{{ addressList.phone }} </span>
                  </div>
                </div>
              </el-form-item>
              <!-- <el-form-item label="选择地址：" v-if="show">
                <div v-if="addressList.length">
                  <el-select v-model="form.address_id" placeholder="请选择" @change="getDeliverymethod" style="width: 500px">
                    <el-option v-for="item in addressList" :key="item.id" :label="item.address_str" :value="item.id"> </el-option>
                  </el-select>
                  <div>
                    <span>收货人：{{ currentAddress[0].name }}</span>
                    <span class="ml_20"> 电话：{{ currentAddress[0].phone }} </span>
                  </div>
                </div>
                <div v-else>
                  <el-button plain @click="$router.push('/index/subRouter/address')"> 去添加地址 </el-button>
                </div>
              </el-form-item> -->
              <!-- <el-form-item label="发票类型：">
                <el-radio-group v-model="form.invoice_id">
                  <el-radio v-for="item in invoice" :key="item.id" :label="item.id">{{ item.name }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="随货资质：">
                <el-radio-group v-model="form.qualification_id">
                  <el-radio v-for="item in qualification" :key="item.id" :label="item.id">{{ item.name }}</el-radio>
                </el-radio-group>
              </el-form-item> -->
            </el-form>
          </div>
        </div>
      </div>

      <el-form :model="form" class="mt_10">
        <el-form-item label="订单备注：">
          <el-input type="textarea" v-model="form.note" :rows="'3'" maxlength="100" show-word-limit placeholder="订单备注"></el-input>
        </el-form-item>
      </el-form>
      <div class="goods">
        <p class="p1">商品清单</p>
        <el-table :data="info.goods" ref="multipleTable" :cell-style="tableRowStyle">
          <el-table-column prop="date" label="商品信息" align="center" width="500">
            <template slot-scope="{ row }">
              <div class="flex_row_aic">
                <img style="width: 87px; height: 87px" :src="row.image.url" alt="" />
                <div class="info">
                  <p class="shenglue_1 bold">{{ row.title }}</p>
                  <p>{{ row.specification }}</p>
                  <p>{{ row.manufacturer }}</p>
                  <p>{{ row.validity_date }}</p>
                  <div class="flex_row" style="flex-wrap: wrap">
                    <div class="tag" v-for="item in row.tags" :key="item.title" :style="`color: #${item.color}; border: 1px solid #${item.color};`">
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="color_theme" style="text-align: left">{{ row.show_memo }}</div>
            </template>
          </el-table-column>
          <el-table-column label="会员价" align="center">
            <template slot-scope="{ row }">
              <span class="f16"> ￥{{ row.price }} </span>
            </template>
          </el-table-column>
          <el-table-column label="原价" align="center">
            <template slot-scope="{ row }">
              <span class="f14" style="text-decoration: line-through"> ￥{{ row.price1 }} </span>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="数量" align="center">
            <template slot-scope="{ row }">
              <span class="f16"> {{ row.number }} </span>
            </template>
          </el-table-column>

          <el-table-column label="合计" align="center">
            <template slot-scope="{ row }">
              <span class="f16"> ￥{{ (Number(row.number) * Number(row.price)).toFixed(2) }} </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex_d_c_c freight_info" v-if="freightInfo.qfje">
        <p>
          配送说明：订单
          <span class="red bold"> {{ freightInfo.qfje }} </span> 元起配，订单满 <span class="red bold">{{ freightInfo.byje }}</span> 元包邮。
        </p>
        <p class="red">{{ freightInfo.bzxx }}</p>
      </div>
      <div class="bottom">
        <div class="float">
          <div>
            <div>
              商品总价： <span class="red">￥{{ info.goods_total_price }}</span>
            </div>
            <div>
              换购金额： <span class="red">￥{{ info.hg_money }}</span>
            </div>
            <div>
              订单优惠金额：
              <span class="color_theme bold">￥{{ info.order_yh_money }}</span>
            </div>
            <div>
              优惠券金额：
              <span class="color_theme bold">￥{{ conponMoney }}</span>
            </div>
            <div>
              商品运费： <span class="red">￥{{ freightInfo.price || 0 }}</span>
            </div>
            <div>
              应付总额： <span class="red">￥{{ payMoney }}</span>
            </div>
            <div v-html="str1"></div>
            <div v-html="str2"></div>
            <div v-html="str3"></div>
          </div>
        </div>
        <div>{{ info.sys_qczk }}{{ info.sys_yhzc }}</div>
      </div>
      <div class="pay_box">
        <div style="width: 380px">
          <el-form :model="form" class="mt_10" label-width="100px" :rules="rules">
            <el-form-item label="可用优惠券：">
              <el-select v-model="form.coupon_id" clearable placeholder="请选择" no-data-text="无可用优惠券" @change="handleCoupon">
                <el-option v-for="item in couponList" :key="item.id" :label="item.title_web" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="支付方式：" prop="zffs_id">
              <el-select v-model="form.zffs_id" placeholder="请选择">
                <el-option v-for="item in payList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="danger" @click="handleSubmit">提交订单</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { handleStr } from "@/utils/tool";
export default {
  data() {
    return {
      alertzf:null,
      lx_ddzt:'',
      show: false,
      form: {
        address_id: "", //地址id
        deliverymethod_id: "", //配送方式id
        coupon_id: "", //优惠券id
        invoice_id: 2, //发票类型id
        qualification_id: 2, //随货资质id
        zffs_id: "", //支付方式
        note: "",
        preorder_id: this.$route.params.id,
      },
      dialogVisible: false,
      info: {},
      endtime:{
        h:0,
        i:0,
        s:0,
      },
      addressList: [],
      deliverymethod: [],
      invoice: [],
      qualification: [],
      couponList: [],
      payList: [],
      freightInfo: {},
      conponMoney: 0,
      str1: "",
      str2: "",
      str3: "",
      rules: {
        deliverymethod_id: [{ required: true, message: "选择配送方式", trigger: "change" }],
        zffs_id: [{ required: true, message: "选择支付方式", trigger: "change" }],
      },
    };
  },
  computed: {
    currentAddress() {
      return this.addressList.filter((e) => e.id == this.form.address_id);
    },
    payMoney() {
      let num = Number(this.info.goods_total_price) - Number(this.info.order_yh_money || 0) - Number(this.conponMoney) + Number(this.freightInfo.price || 0);
      return (Math.round(num * 100) / 100).toFixed(2);
    },
    //goods_total_price -order_yh_money-优惠券+freightInfo.price
  },
  created() {
    console.log(this.$route.params.id)
    if (!this.$route.params.id) {
      this.$router.push("/index/cart");
      this.$alert("请重新下单", "提示", {
        confirmButtonText: "确定",
        callback: () => {},
      });
      return;
    }
    let id = this.$route.params.id;
    this.$api("cart.getPreorder", { id }).then((res) => {
      // console.log(res.data.end_time.i)
      this.info = res.data;

      // console.log('时间'.res.data.end_time)
      this.endtime.h = res.data.end_time.h;
      this.endtime.i = res.data.end_time.i;
      this.endtime.s = res.data.end_time.s;
      
      this.str1 = handleStr(res.data.hb_title);
      this.str2 = handleStr(res.data.mj_title);
      this.str3 = handleStr(res.data.zk_title);
    });
    // 选择地址
    this.$api("cart.getAddress", { id }).then((res) => {
      res.data.forEach((e) => {
        if (e.id == 1) {
          this.form.address_id = e.id;
        }
      });
      let defaultAddress = res.data.filter((v) => {
        return v.id == 1;
      });
      this.addressList = res.data;
      console.log("默认地址----", defaultAddress[0]);
      this.addressList = defaultAddress[0];
      // 选择配送
      this.getDeliverymethod();
    });
    // 发票类型
    this.$api("cart.getInvoice").then((res) => {
      this.invoice = res.data;
    });
    // 随货资质
    this.$api("cart.getQualification").then((res) => {
      this.qualification = res.data;
    });
    // 优惠券
    this.$api("cart.getCoupon", { preorder_id: id, is_available: "101" }).then((res) => {
      this.couponList = res.data;
      let defaultCoupon = this.couponList.filter((item) => {
        return item.is_chosed == 101;
      });
      if (defaultCoupon.length > 0) {
        this.form.coupon_id = defaultCoupon[0].id;
      }
    });
    // 支付方式
    this.$api("cart.getPay").then((res) => {
      console.log("支付方式",res)
      this.payList = res.data;

      res.data.forEach((e) => {
        if (e.is_default == '102') {
          this.form.zffs_id = e.id;
        }
      });
      // zffs_id
    });
  },
  methods: {
    tableRowStyle({ columnIndex }) {
      if (columnIndex == 1 || columnIndex == 3) {
        return "color:#ff4c4c;";
      }
    },
    getDeliverymethod() {
      let p = {
        preorder_id: this.$route.params.id,
        address_id: this.form.address_id,
      };
      this.$api("cart.getDeliverymethod", p).then((res) => {
        this.deliverymethod = res.data;
        res.data.forEach((e) => {
        if (e.is_default == 101) {
          this.form.deliverymethod_id = e.id;
          this.getFreight(this.form.deliverymethod_id)
        }
      });

      });
    },
    getFreight(e) {
      if (e == 3) this.show = false;
      else this.show = true;
      let p = {
        preorder_id: this.$route.params.id,
        deliverymethod_id: this.form.deliverymethod_id,
      };
      this.$api("cart.getFreight", p).then((res) => {
        this.freightInfo = res.data;
      });
    },
    handleCoupon(e) {
      console.log('选择优惠券',e)
      if(e != ''){
        this.couponList.forEach((v) => {
          if (e == v.id) {
            console.log(v.money)
            this.conponMoney = Number(v.money);
          }
        });
      }else{
        this.conponMoney = Number(0);
      }

    },

    sanitizeHtml(html) {
      // 这里简单处理，实际项目中应使用更安全的库，如DOMPurify
      return html.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    },
    handleSubmit() {
      if (!this.form.deliverymethod_id) {
        this.$message.error("请选择配送方式");
        return;
      }
      if (!this.form.zffs_id) {
        this.$message.error("请选择支付方式");
        return;
      }
      console.log("提交订单参数----", this.form);
      // return;
      this.$api("cart.submitOrder", this.form).then((res) => {
        console.log("提交订单结果----", res);
        if (res.data.pay_info.need_pay == 102) {
          this.$router.replace({
            path: "/index/subRouter/order",
            query: { index: 102 },
          });
        } else {
          if (this.form.zffs_id == 2) {
            this.$api("cart.weixinpay", { ddbh: this.$route.params.id }).then((res) => {
              console.log('微信支付',res);
              if (res.data.code == "101") {

                this.get_ddzt();
                // 获取到支付二维码

                var centent = "<div style='display: flex;justify-content: center;'><img style='width: 200px; height: 200px' src="+res.data.code_url+"  /></div>";

                this.$confirm(centent, '微信支付二维码', {
                  dangerouslyUseHTMLString: true,
                  confirmButtonText: '确定',
                  showCancelButton: false,  //是否显示取消按钮
                  showClose: false, //是否显示右上角的x
                  closeOnClickModal: false, //是否可以点击空白处关闭弹窗
                }).then(() => {
                  clearInterval(this.lx_ddzt);
                  this.$router.replace({
                    path: "/index/subRouter/order",
                    query: { index: 101 },
                  });
                });

              
              } else {
                this.$alert(res.data.err, "错误", {
                  confirmButtonText: "确定",
                  callback: () => {
                    this.$router.replace({
                      path: "/index/subRouter/order",
                      query: { index: 101 },
                    });
                  },
                });
              }
            });
          }
        }
      });
    },

    //轮询获取订单状态
    get_ddzt(){
      let that = this;
      that.lx_ddzt = setInterval(() => {
        that.$api("cart.getOrderState", { id: that.$route.params.id }).then((res) => {
          console.log('轮询',res.data.pay_info.need_pay);
          if(res.data.pay_info.need_pay == "102"){
            clearInterval(that.lx_ddzt);
            that.$msgbox.close();
            that.$router.replace({
              path: "/index/subRouter/order",
              query: { index: 102 },
            });
          }
        })

      }, 2000);
    }

  },
};
</script>

<style lang="less" scoped>
.check_container {
  background-color: #fff;
  .orange {
    color: #f1622f;
  }
  .top {
    width: 100%;
    height: 74px;
    .c3 {
      font-weight: bold;
    }
    .step {
      width: 360px;
      .cart_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        &:last-child {
          margin: 0;
        }
        .green {
          margin-top: 10px;
          width: 18px;
          height: 18px;
          background: @themeColor;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          position: relative;
        }
        .grey {
          margin-top: 10px;
          width: 18px;
          height: 18px;
          background: #c6c6c6;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          position: relative;
        }
        .line {
          width: 51px;
          height: 3px;
          background-color: #e8e8e8;
          position: absolute;
          left: 18px;
        }
        .line1 {
          width: 51px;
          height: 3px;
          background-color: #e8e8e8;
          position: absolute;
          right: 18px;
        }
        .active_bg {
          background-color: @themeColor;
        }
      }
    }
  }
  .address {
    width: 100%;
    border: 1px solid #dddddd;
    .nav {
      width: 100%;
      height: 36px;
      background: #f3f3f3;
      color: #333;
      padding: 0 15px;
      box-sizing: border-box;
    }
    .main {
      padding: 15px;
      padding-bottom: 0;
      box-sizing: border-box;
      .tip {
        font-size: 12px;
        color: #f1622f;
        i {
          font-size: 14px;
        }
      }
      .info {
        margin-top: 10px;
      }
    }
  }
  .goods {
    width: 100%;
    margin-top: 20px;
    .p1 {
      font-weight: bold;
      color: #333333;
      margin-bottom: 15px;
    }
    .info {
      text-align: left;
      margin-left: 10px;
      width: 100%;
      .tag {
        padding: 0 6px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .red {
    font-size: 14px;
    color: #f93232;
  }
  .freight_info {
    line-height: 2;
    padding: 20px 0;
  }
  .bottom {
    padding: 20px 30px;
    box-sizing: border-box;
    width: 100%;
    background: #ebebeb;
    border-radius: 4px;
    color: #333;
    line-height: 2;
    .float {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .red {
      font-weight: bold;
      color: #f93232;
      font-size: 14px;
    }
  }
  .pay_box {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
